import React, { PureComponent } from 'react'
import { withTranslation } from 'react-i18next'
import Dropzone from 'react-dropzone'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { map, isEmpty, remove, get, indexOf, orderBy, isEqual, filter } from 'lodash'
import cx from 'classnames'

// components
import DefaultModal from '../components/Modals/DefaultModal'

// utils
import { openDataUriWindow, getBase64FromFile } from '../utils/files'

class UploadField extends PureComponent {
	static propTypes = {
		input: PropTypes.shape({
			name: PropTypes.string.isRequired,
			value: PropTypes.any,
			onChange: PropTypes.func.isRequired
		}).isRequired,
		meta: PropTypes.shape({
			touched: PropTypes.bool,
			error: PropTypes.string
		}).isRequired,
		dokumentTyp: PropTypes.shape({
			id: PropTypes.number.isRequired,
			nazov: PropTypes.string.isRequired
		}),
		onDelete: PropTypes.func,
		placeholder: PropTypes.string,
		t: PropTypes.func.isRequired,
		labelText: PropTypes.string,
		disabled: PropTypes.bool,
		multiple: PropTypes.bool
	}

	constructor(props) {
		super(props)
		this.state = {
			files: [],
			currentUrl: '',
			loading: false,
			showRemoveConfirmModal: null
		}
	}

	componentDidMount() {
		const { input, dokumentTyp } = this.props

		let files = []

		if (isEmpty(dokumentTyp)) {
			files = this.orderFiles([...get(input, 'value', [])])
		} else {
			files = this.orderFiles([...get(input, 'value', [])]?.filter((file) => file?.dokumentTyp?.id === dokumentTyp.id))
		}

		this.setState({
			files
		})
	}

	componentDidUpdate(prevProp) {
		const { input, dokumentTyp } = this.props

		if (!isEqual(get(prevProp, 'input.value'), get(input, 'value'))) {
			let files = []
			if (isEmpty(dokumentTyp)) {
				files = this.orderFiles([...get(input, 'value', [])])
			} else {
				files = this.orderFiles([...get(input, 'value', [])]?.filter((file) => file?.dokumentTyp?.id === dokumentTyp.id))
			}
			this.setState({
				files
			})
		}
	}

	orderFiles(files) {
		return orderBy(files, ['pridanyOd'], ['desc'])
	}

	removeFile = async (index) => {
		const { input, onDelete } = this.props
		const { files } = this.state

		const value = [...get(input, 'value', [])]

		// if a file was already upload call onDelete callback for removing via API call
		const fileIDForAPIRemove = get(files, `[${index}].id`, null)

		let removedFileIDs = []
		if (onDelete && fileIDForAPIRemove) {
			removedFileIDs = await onDelete(fileIDForAPIRemove)
		}

		// NOTE: removing file related to this field from dokumenty("input") value
		let updatedValue = []
		if (isEmpty(removedFileIDs)) {
			updatedValue = value?.filter((val) => !isEqual(val, get(files, `[${index}]`, null)))
		} else {
			updatedValue = value?.filter((val) => indexOf(removedFileIDs, get(val, 'id')) < 0)
		}

		input.onChange([...updatedValue])
	}

	onDrop = async (files) => {
		const { input, dokumentTyp } = this.props
		const loaded = 0

		this.setState({
			loaded,
			loading: true
		})

		// transform file to primitive object as prevention for redux state mutation
		const normalizeFilesPromises = map(files, async (file) => {
			const base64 = await getBase64FromFile(file)
			const dokument = base64 ? base64.split(',') : ''

			const document = {
				dataAsBase64: dokument[1],
				name: file.name,
				type: file.type
			}

			if (!isEmpty(dokumentTyp)) {
				return {
					...document,
					dokumentTyp
				}
			}
			return document
		})

		const normalizeFiles = await Promise.all(normalizeFilesPromises)
		const value = get(input, 'value', [])
		const orderedFiles = this.orderFiles([...value, ...normalizeFiles])

		this.setState(
			{
				files: orderedFiles
			},
			() => {
				input.onChange(orderedFiles)
			}
		)
	}

	render() {
		const {
			input: { name },
			meta: { touched, error },
			placeholder,
			labelText,
			disabled,
			t,
			multiple
		} = this.props
		const { files, showRemoveConfirmModal } = this.state

		const areFiles = files && files.length > 0

		let modal
		if (get(showRemoveConfirmModal, 'visible')) {
			modal = (
				<DefaultModal
					modalTitle={t('translation:Common.Upozornenie!')}
					modalContent={t('translation:Common.Naozaj chcete súbor odstrániť?')}
					rightButton={{
						onClick: () =>
							this.setState({
								showRemoveConfirmModal: {
									visible: false,
									index: null
								}
							}),
						text: t('translation:Common.Späť'),
						color: 'blue'
					}}
					leftButton={{
						onClick: () => {
							this.removeFile(get(showRemoveConfirmModal, 'index'))
							this.setState({
								showRemoveConfirmModal: {
									visible: false,
									index: null
								}
							})
						},
						text: t('translation:Common.Odstrániť'),
						color: 'red',
						outline: true
					}}
					visible
				/>
			)
		}

		let fileCounter = null
		if (areFiles) {
			fileCounter = map(files, (file, index) => {
				const showDeleteButton = get(file, 'id') || get(file, 'dataAsBase64')

				return (
					<div key={index} className='file-box'>
						<div>
							{get(file, 'id') ? (
								<a
									className='file'
									onClick={() => openDataUriWindow(get(file, 'id'), get(file, 'name') || get(file, 'nazov'))}
									data-type='general'
									style={{ cursor: 'pointer' }}
								>
									{get(file, 'name') || get(file, 'nazov')}
								</a>
							) : (
								get(file, 'name') || get(file, 'nazov')
							)}
							{get(file, 'dokumentDruh') && (
								<span className='label' style={{ marginLeft: '15px', textTransform: 'none' }} data-color='blue'>
									{get(file, 'dokumentDruh.nazov')}
								</span>
							)}
						</div>
						<div className='actions-btn-box'>
							{showDeleteButton && (
								<a
									className='remove-file-btn'
									onClick={() =>
										this.setState({
											showRemoveConfirmModal: {
												visible: true,
												index
											}
										})
									}
								/>
							)}
						</div>
					</div>
				)
			})
		}

		return (
			<div className={cx('upload-field', { 'danger-box': error })}>
				{labelText && <strong className='upload-label'>{labelText}</strong>}
				{!areFiles && (
					<div className='upload-empty-state'>
						<div>
							<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
								<div className='empty-icon' />
							</div>
							<p>{t('translation:Common.Žiadne dokumenty')}</p>
						</div>
					</div>
				)}
				<div className='input-wrapper upload-dropzone-wrapper'>
					{areFiles && <div className='file-list'>{fileCounter}</div>}
					<Dropzone name={name} onDrop={this.onDrop} disabled={disabled} multiple={multiple}>
						{({ getRootProps, getInputProps }) => (
							<div data-name={name} className={cx('dropzone', { 'has-error': touched && error })} {...getRootProps()}>
								<input name={name} {...getInputProps()} />
								<p>{placeholder || t('translation:Common.Klikni alebo presuň súbory pre nahratie')}</p>
							</div>
						)}
					</Dropzone>
					<div className='text-danger'>{touched ? error : ''}</div>
				</div>
				{modal}
			</div>
		)
	}
}

export default compose(withTranslation('components'))(UploadField)
